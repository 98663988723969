// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-comparison-table-js": () => import("./../../../src/pages/comparison_table.js" /* webpackChunkName: "component---src-pages-comparison-table-js" */),
  "component---src-pages-costs-js": () => import("./../../../src/pages/costs.js" /* webpackChunkName: "component---src-pages-costs-js" */),
  "component---src-pages-environment-js": () => import("./../../../src/pages/environment.js" /* webpackChunkName: "component---src-pages-environment-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-incidents-js": () => import("./../../../src/pages/incidents.js" /* webpackChunkName: "component---src-pages-incidents-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mission-js": () => import("./../../../src/pages/mission.js" /* webpackChunkName: "component---src-pages-mission-js" */),
  "component---src-pages-presentation-js": () => import("./../../../src/pages/presentation.js" /* webpackChunkName: "component---src-pages-presentation-js" */),
  "component---src-pages-references-js": () => import("./../../../src/pages/references.js" /* webpackChunkName: "component---src-pages-references-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */)
}

